<template>
    <div class="field field-select" :class="{ 'is-invalid': !!errorMessage }">
        <label v-if="!formFloating && label" class="mb-2" :for="id">
            {{ label }}<span v-if="mandatory" class="text-danger ms-2">*</span>
        </label>
        <div class="form-control-wrapper" :class="{ 'form-floating': formFloating }">
            <select
                class="form-select"
                :class="{ 'is-invalid': !!errorMessage }"
                @change="onHandleChange($event)"
                :name="name"
                :id="id"
                :disabled="disabled"
                :value="modelValue">
                <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
                <option v-for="item in items"
                    :value="getItemValue(item)">
                    {{ getItemText(item) }}
                </option>
            </select>
            <label v-if="formFloating && label" :for="id">{{ label }}</label>
        </div>
        <FadeInOut :duration="150">
            <p v-if="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
        </FadeInOut>
    </div>
</template>

<script lang="ts" setup>
import { FadeInOut } from 'vue3-transitions';

interface Props {
    id?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    itemText: string;
    itemValue: string;
    mandatory?: boolean;
    disabled?: boolean;
    items?: Array<any>;
    formFloating?: boolean;
    modelValue?: string|any;
    errorMessage?: string;
}

const props = withDefaults(defineProps<Props>(), {
    items: () => []
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: string|any): void;
    (e: 'change'): void
}>();

const getItemText = (item?: any) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    if (!props.itemText) return '';
    return item[props.itemText as keyof typeof item];
}

const getItemValue = (item?: string|object) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    return item[props.itemValue as keyof typeof item]
}

const onHandleChange = (e: Event) => {
    const target = e.target as HTMLSelectElement;
    emit('update:modelValue', target.value);
    emit('change')
}
</script>
